import React from 'react'
import Image from './Image'
import Button from './Button'
import ReactMarkdown from 'react-markdown'

const PageIntro = ({
	title,
	summary,
	image = undefined,
	buttonLink,
	buttonText,
}) => {
	return (
		<section
			data-section-id="page-intro"
			data-image-align="left"
			data-has-image={image ? 'true' : null}
		>
			<div className="gc">
				{image && (
					<div className="image span-5 m-1-9 r-1">
						<Image source={image} clipPath="left" ratio="1-1" />
					</div>
				)}

				<div className="text auto-layout span-5 m-span-12 r-1 m-r-2 flex flex-column flex-center">
					{title && <h2>{title}</h2>}
					{summary && (
						<div>
							<ReactMarkdown className="richtext">{summary}</ReactMarkdown>
						</div>
					)}
					{buttonLink && buttonText && (
						<Button
							text={buttonText}
							url={buttonLink.Slug ? `/pages/${buttonLink.Slug}` : buttonLink}
							className="primary"
							type="button"
						/>
					)}
				</div>
			</div>
		</section>
	)
}

export default PageIntro
